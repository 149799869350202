import PropTypes from 'prop-types';
import { isEmpty, isNumber, isObject, noop } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { ReactTableDefaults } from 'react-table';

import {
  Table,
  Thead,
  Tfoot,
  Tbody,
  Tr,
  TrGroup,
  Th,
  Td,
  Resizer,
  Loader,
  Expander,
  Pagination,
  NoData,
} from './utils/components';
import { ToolbarFilter } from './Filter';

import * as styled from './styles';

const reactTableDefaultProps = {
  manual: true,
  filterable: true,
  sortable: true,
  multiSort: true,
};

const getTheadTrProps = (props) => ({
  withFixedActions: props.withFixedActions,
});

// NOTE: Map `sorted` and `sortable` into props a ThComponent will receive
// so we can visually describe active sorting and sorting direction
const getTheadThProps = (props, _, column) => ({
  sortable: column.sortable,
  sorted: props.sorted.find((col) => col.id === column.id) || null,
  dataManual: column.dataManual,
  filterable: props.filterable,
  dataColumnId: column.id,
  filterToggle: column.expander && props.filterToggle,
  onFilterableChange: props.onFilterableChange,
  withFixedActions: column.withFixedActions,
});

// NOTE: Map `filtered` and `filterable` into props a ThComponent will receive
// so we can visually describe active filters
const getTheadFilterThProps = (props, _, column) => ({
  expander: column.expander,
  hasActiveFilters: !isEmpty(props.filtered),
  defaultFiltered: props.defaultFiltered,
  onFilteredChange: props.onFilteredChange,
  loading: props.loading,
  filterable: column.filterable,
  filtered: props.filtered.find((col) => col.id === column.id) || null,
  allActiveFilters: props.filtered,
  externalFiltered: props.externalFiltered,
});

const getTheadFilterProps = (props) => ({
  filterable: props.filterable,
});

// NOTE: Map `loading` into props a NoDataComponent will receive
// so we can hide the component as long as data fetching is in progress
const getNoDataProps = (props) => ({
  loading: props.loading,
  onFilteredChange: props.onFilteredChange,
});

const getTrGroupProps = (_, row) => ({
  empty: !row,
});

const getTrProps = (props) => ({
  withFixedActions: props.withFixedActions,
  onClick: props.onClick,
});

const getTableProps = (props) => ({
  scrollable: props.scrollable,
});

const getProps = () => ({
  'data-manual': 'table',
});

const getTbodyProps = () => ({
  'data-role': 'tbody',
  'data-manual': 'components.lists.table.body',
});

const getTdProps = (rest) => (props, row, column) => ({
  isExpander: column.expander,
  withTooltip: column.withTooltip,
  withFixedActions: column.withFixedActions,
  dataColumnId: column.id,
  ...((column.getTdProps && column.getTdProps(props, row, column)) || {}),
  ...((rest.getTdProps && rest.getTdProps(props, row, column)) || {}),
});

const getToolbarFilterProps = (props) => ({
  filtered: props.filtered,
  onChange: props.onFilteredChange,
  ...props.toolbarFilter,
});

export const TableComponent = (props) => {
  const {
    data,
    columns,

    paginated,
    columnProps,

    actions,
    totalCountLabel,
    scrollable,

    withToolbar,
    toolbarFilter,

    getTdProps: _, // eslint-disable-line @typescript-eslint/no-unused-vars

    ...rest
  } = props;

  const { totalCount } = paginated;
  const hasToolbarFilter = !!toolbarFilter;

  return (
    <styled.Container>
      {withToolbar && (
        <styled.Toolbar extended={hasToolbarFilter}>
          {hasToolbarFilter && (
            <ToolbarFilter {...getToolbarFilterProps(props)} />
          )}

          {isNumber(totalCount) && isObject(totalCountLabel) && (
            <styled.Count grow={!hasToolbarFilter}>
              <FormattedMessage
                {...totalCountLabel}
                values={{ count: totalCount }}
              />
            </styled.Count>
          )}

          {actions && <styled.Actions>{actions}</styled.Actions>}
        </styled.Toolbar>
      )}
      <styled.CustomReactTable
        {...reactTableDefaultProps}
        data={data}
        columns={columns}
        page={paginated.page}
        pages={paginated.pages}
        pageSize={paginated.pageSize}
        pageSizeOptions={paginated.pageSizeOptions}
        totalCount={totalCount}
        scrollable={scrollable}
        minRows={scrollable && data.length > 0 ? 15 : 0}
        showPaginationTop={false}
        showPaginationBottom
        getTheadTrProps={getTheadTrProps}
        getTheadThProps={getTheadThProps}
        getTheadFilterThProps={getTheadFilterThProps}
        getTheadFilterProps={getTheadFilterProps}
        getNoDataProps={getNoDataProps}
        getTrGroupProps={getTrGroupProps}
        getTrProps={getTrProps}
        getTableProps={getTableProps}
        getProps={getProps}
        getTbodyProps={getTbodyProps}
        getTdProps={getTdProps(props)}
        column={{
          ...ReactTableDefaults.column,
          sortable: false,
          multiSort: false,
          filterable: false,
          ...columnProps,
        }}
        expanderDefaults={{
          ...ReactTableDefaults.expanderDefaults,
          width: 20,
        }}
        TableComponent={Table}
        TheadComponent={Thead}
        TbodyComponent={Tbody}
        TfootComponent={Tfoot}
        TrComponent={Tr}
        TrGroupComponent={TrGroup}
        ThComponent={Th}
        TdComponent={Td}
        LoadingComponent={Loader}
        ResizerComponent={Resizer}
        ExpanderComponent={Expander}
        FilterComponent={noop}
        PaginationComponent={Pagination}
        NoDataComponent={NoData}
        SubComponent={noop}
        totalCountLabel={totalCountLabel}
        {...rest}
      />
    </styled.Container>
  );
};

TableComponent.propTypes = {
  scrollable: PropTypes.bool,
  filterToggle: PropTypes.bool,
  stickyPagination: PropTypes.bool,
  data: PropTypes.array,
  columns: PropTypes.array,
  paginated: PropTypes.object,
  columnProps: PropTypes.object,
  loading: PropTypes.bool,
  filtered: PropTypes.array,
  externalFiltered: PropTypes.object,
  sorted: PropTypes.array,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onFilteredChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  onExpandedChange: PropTypes.func,
  filterable: PropTypes.bool,
  resizable: PropTypes.bool,
  withFixedActions: PropTypes.bool,
  hoverColor: PropTypes.string,
  NoDataComponent: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.node),
  totalCountLabel: PropTypes.object,
  toolbarFilter: PropTypes.object,
  withToolbar: PropTypes.bool,
  onFilterableChange: PropTypes.func,
  getTdProps: PropTypes.func,
  SubComponent: PropTypes.func,
};

TableComponent.defaultProps = {
  data: [],
  columns: [],
  paginated: {},
  columnProps: {},

  actions: [],
  totalCountLabel: null,
  toolbarFilter: null,
  scrollable: false,
  withToolbar: true,
  filterToggle: false,
  stickyPagination: true,
  onFilterableChange: noop,
};

import { isValidElement, Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  get,
  find,
  remove,
  filter,
  isEmpty,
  isObject,
  isString,
  intersectionBy,
  escapeRegExp,
} from 'lodash';

import { isValid } from 'components/utils/form-utils';
import { Checkbox } from 'components/form/checkbox/checkbox';
import { Scrollbars } from 'components/misc/Scrollbars';
import { Icon } from 'components/elements/icon';
import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles/checkboxList';

export class CheckboxList extends Component {
  constructor(props) {
    const { checked, items } = props;

    super(props);
    this.state = { checked: intersectionBy(items, checked, 'id') };
  }

  static getDerivedStateFromProps(nextProps) {
    const { checked, items, filtered } = nextProps;

    return {
      checked: filtered ? checked : intersectionBy(items, checked, 'id'),
    };
  }

  handleCheckboxChange({ id, checked: isChecked }) {
    const { checked } = this.state;
    const { items, idSelector, onChange, unchecking } = this.props;
    const item = find(items, [idSelector, id]);
    const alreadyChecked = !!find(checked, [idSelector, id]);

    // prevent firing callback twice for the same action
    if (isChecked === alreadyChecked) {
      return;
    }

    // prevent un-checking checkbox if configured that way
    if (unchecking === false && !isChecked) {
      return;
    }

    if (isChecked) {
      checked.push(item);
    } else {
      remove(checked, (it) => it[idSelector] === id);
    }

    this.setState({ checked });
    onChange(checked);
  }

  itemName(item) {
    const { nameSelector } = this.props;
    const name = get(item, nameSelector);

    if (isValidElement(name)) {
      return name;
    }
    if (isObject(name)) {
      return <FormattedMessage {...name} />;
    }
    return name;
  }

  items() {
    const { items, filterKeyword } = this.props;
    if (filterKeyword === undefined) {
      return items;
    }

    const re = new RegExp(escapeRegExp(filterKeyword), 'i');
    return filter(items, (item) => re.test(this.itemName(item)));
  }

  renderIcon(item) {
    const { iconContextSelector } = this.props;
    const context = item[iconContextSelector];

    if (!iconContextSelector || !context) {
      return null;
    }

    if (isString(context)) {
      return <styled.Icon icon={context} />;
    }

    return <styled.Avatar context={context} />;
  }

  renderItem(item) {
    const { idSelector, checkboxOnLeft, required, shouldValidate } = this.props;
    const { checked } = this.state;
    const id = get(item, idSelector);
    const isChecked = !!find(checked, [idSelector, get(item, idSelector)]);
    const isListValid = isValid({
      inputValue: checked,
      shouldValidate,
      inputType: 'checkbox-list',
      required,
    });

    return (
      <styled.ListItem key={id}>
        <styled.Label htmlFor={id}>
          {!checkboxOnLeft && (
            <styled.LabelTitle>
              {this.renderIcon(item)}
              {this.itemName(item)}
            </styled.LabelTitle>
          )}
          <styled.FlexContainer>
            {item.tooltipContent && !checkboxOnLeft && (
              <styled.Tooltip
                content={item.tooltipContent}
                size="normal"
                position="S"
                fixed
                shadow={false}
              >
                <Icon color={Colors.tealDark} icon="Info" size="large" />
              </styled.Tooltip>
            )}
            <Checkbox
              id={id}
              checked={isChecked}
              disabled={item.disabled}
              shouldValidate={false}
              isCheckboxListValid={isListValid}
              onChange={(val) => this.handleCheckboxChange(val)}
            />
          </styled.FlexContainer>
          {checkboxOnLeft && (
            <styled.LabelTitle>
              {this.renderIcon(item)}
              {this.itemName(item)}
            </styled.LabelTitle>
          )}
        </styled.Label>
      </styled.ListItem>
    );
  }

  renderItems() {
    return this.items().map((item) => this.renderItem(item));
  }

  render() {
    const { className, scrollbarsHeight, scrollbarsMaxHeight } = this.props;

    return (
      <styled.List className={className}>
        {isEmpty(scrollbarsHeight) ? (
          this.renderItems()
        ) : (
          <Scrollbars
            vertical
            height={scrollbarsHeight}
            maxHeight={scrollbarsMaxHeight}
          >
            {this.renderItems()}
          </Scrollbars>
        )}
      </styled.List>
    );
  }
}

CheckboxList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  checked: PropTypes.array.isRequired,
  required: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  idSelector: PropTypes.string.isRequired,
  nameSelector: PropTypes.string.isRequired,
  iconContextSelector: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filterKeyword: PropTypes.string,
  filtered: PropTypes.bool,
  scrollbarsHeight: PropTypes.string,
  scrollbarsMaxHeight: PropTypes.string,
  unchecking: PropTypes.bool,
  checkboxOnLeft: PropTypes.bool,
};

CheckboxList.defaultProps = {
  checkboxOnLeft: false,
};

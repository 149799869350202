import { compact } from 'lodash';

/**
 * @function getBase64
 * @param {object} object of File type
 * @return {string} file encoded to Base64
 */
export const getBase64 = (file: Blob | File) =>
  new Promise((resolve, reject) => {
    const blob = new Blob([file], { type: file.type });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function downloadFile(url: string, name = 'download') {
  const link = document.createElement('a');
  // [download] attribute is only honored for links to resources with the same-origin.
  link.download = name;
  link.href = url;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const EXT_MIME = {
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCM: 'application/vnd.ms-word.document.macroEnabled.12',
  DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOT: 'application/msword',
  DOTM: 'application/vnd.ms-word.template.macroEnabled.12',
  DOTX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  MD: 'text/plain',
  ODT: 'application/vnd.oasis.opendocument.text',
  RTF: 'application/rtf,.rtf',
  TXT: 'text/plain,.txt',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  BMP: 'image/bmp',
  GIF: 'image/gif',
  TIFF: 'image/tiff',
  SVG: 'image/svg+xml',
  ICO: 'image/x-icon,image/vnd.microsoft.icon',
  RAR: 'application/rar,application/x-rar-compressed',
  ZIP:
    'application/zip,application/x-zip-compressed,application/x-compressed,multipart/x-zip',
  AAC: 'audio/aac',
  MP3: 'audio/mpeg',
  WAV: 'audio/wav',
  EPUB: 'application/epub+zip',
  EML: 'message/rfc822',
  MSG: 'application/vnd.ms-outlook',
  ICS: 'text/calendar',
  VCF: 'text/x-vcard',
  ODP: 'application/vnd.oasis.opendocument.presentation',
  OTP: 'application/vnd.oasis.opendocument.presentation-template',
  POT: 'application/vnd.ms-powerpoint',
  POTM: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  POTX: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  PPS: 'application/mspowerpoint,application/vnd.ms-powerpoint',
  PPSM: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  PPSX:
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PPT: 'application/vnd.ms-powerpoint',
  PPTM: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  PPTX:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  KEY: 'application/vnd.apple.keynote',
  MPP: 'application/vnd.ms-project',
  MPT: 'application/x-project',
  MPX: 'application/x-project',
  CSV: 'text/csv',
  ODS: 'application/vnd.oasis.opendocument.spreadsheet',
  TSV: 'text/tab-separated-values',
  XLM: 'application/vnd.ms-excel',
  XLS:
    'application/excel,application/vnd.ms-excel,application/x-excel,application/x-msexcel',
  XLSB: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  XLSM: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLT: 'application/vnd.ms-excel',
  XLTM: 'application/vnd.ms-excel.template.macroEnabled.12',
  XLTX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  AVI: 'video/x-msvideo',
  M4V: 'video/x-m4v',
  MOV: 'video/quicktime',
  WMV: 'video/x-ms-wmv',
  MKV: 'video/x-matroska',
  MP4: 'video/mp4',
  MPG: 'video/mpeg',
  MPEG: 'video/mpeg',
  XML: 'application/xml,text/xml',
};

export const MIME_DESC = {
  pdf: ['PDF'],
  doc: [
    'DOC',
    'DOCM',
    'DOCX',
    'DOT',
    'DOTM',
    'DOTX',
    'MD',
    'ODT',
    'RTF',
    'TXT',
  ],
  image: ['PNG', 'JPG', 'JPEG', 'BMP', 'GIF', 'TIFF'],
  icon: ['ICO', 'SVG'],
  archive: ['RAR', 'ZIP'],
  audio: ['AAC', 'MP3', 'WAV'],
  ebook: ['EPUB'],
  email: ['EML', 'MSG'],
  calendar: ['ICS'],
  contact: ['VCF'],
  presentation: [
    'ODP',
    'OTP',
    'POT',
    'POTM',
    'POTX',
    'PPS',
    'PPSM',
    'PPSX',
    'PPT',
    'PPTM',
    'PPTX',
    'KEY',
  ],
  project: ['MPP', 'MPT', 'MPX', 'XER'],
  spreadsheet: [
    'CSV',
    'DIF',
    'ODS',
    'TSV',
    'XLM',
    'XLS',
    'XLSB',
    'XLSM',
    'XLSX',
    'XLT',
    'XLTM',
    'XLTX',
  ],
  video: ['AVI', 'M4V', 'MOV', 'WMV', 'MKV', 'MP4', 'MPG', 'MPEG'],
  web: ['XML'],
};

/**
 * @function acceptedMimeTypes
 * @param {array} accepted file types
 * @return {string} comma-separated list of MIME types
 */
export function acceptedMimeTypes(formats: string[]) {
  return compact(
    formats
      .flatMap((format) => MIME_DESC[format as keyof typeof MIME_DESC])
      .map((ext) => EXT_MIME[ext as keyof typeof EXT_MIME])
  ).join(',');
}

/**
 * @function humanReadebleFileSize
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanReadebleFileSize(bytes: number, si = false, dp = 0) {
  const threshold = si ? 1000 : 1024;

  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let result = bytes;
  let u = -1;
  const r = 10 ** dp;

  do {
    result /= threshold;
    u += 1;
  } while (
    Math.round(Math.abs(result) * r) / r >= threshold &&
    u < units.length - 1
  );

  return `${result.toFixed(dp)} ${units[u]}`;
}

/**
 * Fetches a file from a given URL and creates a File object.
 *
 * @param url - The URL of the file to fetch
 * @param fileName - The desired name for the fetched file
 * @returns A Promise resolving to a File object
 * @throws {Error} If the network request fails or file conversion fails
 *
 */
export async function fetchFile(url: string, fileName: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const fileType = blob.type || 'application/octet-stream';
  return new File([blob], fileName, { type: fileType });
}

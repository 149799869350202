import { FormattedMessage } from 'react-intl';
import { compact, sortBy, startsWith } from 'lodash';

import { shouldHideCandidateDbFromMenu } from 'utils/checkCompany';
import { IntercomAPI } from 'components/scripts/intercom';
import { userRole } from 'utils/authorization/utils/auth';
import i18n from 'scenes/utils/i18n';
import {
  listsEnabled,
  pdfExportOnly,
} from 'components/utils/subscriptionPlans';

export const sidebarMenuAdmin = ({ company_type: companyType }) =>
  compact([
    {
      label: <FormattedMessage {...i18n.adminCompanies} />,
      path: '/admin/companies',
      slug: 'adminCompanies',
      icon: 'Users',
    },
    {
      label: <FormattedMessage {...i18n.adminIntegrations} />,
      path: '/admin/integrations',
      slug: 'adminIntegrations',
      icon: 'Gear',
    },
    companyType === 'administrator' && {
      label: <FormattedMessage {...i18n.adminMessages} />,
      path: '/admin/messages',
      slug: 'adminMessages',
      icon: 'Chat',
    },
    {
      label: <FormattedMessage {...i18n.adminSubscriptionPlans} />,
      path: '/admin/subscription-plans',
      slug: 'adminSubscriptionPlans',
      icon: 'Shield',
    },
    companyType === 'administrator' && {
      label: <FormattedMessage {...i18n.adminHotlist} />,
      path: '/admin/hotlist',
      slug: 'adminHotlist',
      icon: 'Fire',
    },
    {
      label: <FormattedMessage {...i18n.adminDataAnaylsis} />,
      path: '/admin/data-analysis',
      slug: 'adminDataAnalysis',
      icon: 'Stack',
    },
    {
      label: <FormattedMessage {...i18n.adminOpenAI} />,
      path: '/admin/openai',
      slug: 'adminOpenAI',
      icon: 'Gpt',
    },
    {
      label: <FormattedMessage {...i18n.adminSettings} />,
      path: '/admin/settings',
      slug: 'adminSettings',
      icon: 'Sliders',
    },
  ]);

const sidebarMenu = (company) => {
  const { id: companyId } = company;

  const items = compact([
    {
      label: <FormattedMessage {...i18n.sharedLists} />,
      path: '/submission-lists?sub-sidebar=expanded',
      icon: 'UserList',
      slug: 'shared_lists',
      position: 1,
      counter: { key: 'shared_lists.submission_lists_total_unread' },
      isActive: (match, { pathname }) =>
        match || startsWith(pathname, '/submission-lists'),
    },
    {
      label: <FormattedMessage {...i18n.marketingLists} />,
      path: '/marketing-lists?sub-sidebar=expanded',
      icon: 'Megaphone',
      slug: 'shared_lists_marketing',
      position: 1,
      counter: { key: 'shared_lists.marketing_lists_total_unread' },
      isActive: (match, { pathname }) =>
        match || startsWith(pathname, '/marketing-lists'),
    },
    listsEnabled(company) && {
      label: <FormattedMessage {...i18n.messages} />,
      path: '/messages',
      icon: 'ChatCircle',
      counter: { key: 'shared_lists.messages_unread' },
      slug: 'all-messages',
      position: 1,
    },
    !shouldHideCandidateDbFromMenu(companyId) && {
      label: <FormattedMessage {...i18n.candidates} />,
      path: '/candidates',
      icon: 'Users',
      slug: 'candidates',
      position: pdfExportOnly(company) ? 0 : 1,
    },
    listsEnabled(company) && {
      label: <FormattedMessage {...i18n.clients} />,
      path: '/clients',
      icon: 'Suitcase',
      slug: 'clients',
      position: 1,
    },
    {
      label: <FormattedMessage {...i18n.analytics} />,
      path: '/reporting',
      icon: 'ChartLineUp',
      slug: 'reporting',
      position: 1,
    },
  ]);

  return sortBy(items, (item) => item.position);
};

export const buildSidebarMenu = (company) => {
  if (
    company.company_type === 'administrator' ||
    company.company_type === 'fuse_admin'
  ) {
    return sidebarMenuAdmin(company);
  }

  return sidebarMenu(company);
};

export const sidebarMenuProfile = (company) => {
  const companyType = company.company_type;
  const isMember = userRole() === 'member';
  const hideSettings = isMember && pdfExportOnly(company);

  return compact([
    companyType !== 'administrator' &&
      companyType !== 'fuse_admin' &&
      !hideSettings && {
        label: <FormattedMessage {...i18n.settings} />,
        path: '/settings/company',
        icon: 'Gear',
      },
    companyType !== 'administrator' &&
      companyType !== 'fuse_admin' && {
        label: <FormattedMessage {...i18n.yourAccount} />,
        path: '/account',
        icon: 'User',
        border: isMember ? 'bottom' : '',
      },
    companyType !== 'administrator' &&
      !isMember &&
      companyType !== 'fuse_admin' && {
        label: <FormattedMessage {...i18n.subscription} />,
        path: '/subscription-plan',
        icon: 'Shield',
        border: 'bottom',
      },
    {
      label: <FormattedMessage {...i18n.support} />,
      onAction: () => IntercomAPI('show'),
    },
    {
      label: <FormattedMessage {...i18n.helpCenter} />,
      onAction: () => {
        window.open('//help.candidate.ly/', '_blank');
      },
      border: 'bottom',
    },
    {
      label: <FormattedMessage {...i18n.logout} />,
      path: '/logout',
    },
  ]);
};

import { useRef } from 'react';
import murmurhash from 'murmurhash';
import { random } from 'lodash';

import { timeUnitsAgo } from 'components/utils/date';
import { avatarUrl, resizedImageUrl, assetId } from 'components/utils/url';
import { profileInitialsColors } from 'components/utils/styles/ui';

import * as styled from './styles/avatar';
import { BOX_SIZES } from './constants/avatar';
import type { BoxSize } from './constants/avatar';

const COLOR_COUNT = profileInitialsColors.length;

const stringToColor = (value: string) =>
  profileInitialsColors[murmurhash.v3(value) % COLOR_COUNT];

export type ContextProps = {
  picture?: string | File | null;
  initials?: string | null;
  marker?: object;
  initialsLength?: number;
  color?: string | null;
  score?: number;
  email?: string | null;
};

type AvatarProps = {
  className?: string;
  context: ContextProps;
  noInitials?: boolean;
  size?: BoxSize;
  shape?: 'square-rounded' | 'rounded';
  color?: string;
  opacity?: number;
  activity?: boolean | string;
  activityShape?: 'square-rounded' | 'rounded';
};

export const Avatar = function ProfilePicture(props: AvatarProps) {
  const {
    context,
    size = 'medium',
    shape = 'rounded',
    className,
    noInitials = false,
    activity = false,
    activityShape,
    opacity = 1.0,
    color: propColor,
  } = props;
  const retriesUsed = useRef(0);
  const { picture = '', initials, marker, initialsLength = 2, color } = context;
  const text = (initials || '').substring(0, initialsLength).toUpperCase();
  let computedColor =
    (context.email && stringToColor(context.email)) ||
    propColor ||
    color ||
    (initials && stringToColor(initials)) ||
    profileInitialsColors[random(0, COLOR_COUNT - 1, false)];

  computedColor = computedColor?.includes('#')
    ? computedColor.substring(1)
    : computedColor;

  const imgSize = BOX_SIZES[size] * 10 * 2;
  const showIndicator = activity !== false;
  const showMarker = !showIndicator && marker;
  const initialsSrc = avatarUrl(imgSize, text, computedColor, initialsLength);

  const handleAvatarError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    if (!text) return;
    const element = event.target as HTMLImageElement;
    if (retriesUsed.current <= 5) {
      setTimeout(() => {
        element.src = initialsSrc;
        if (element.parentNode) {
          (element.parentNode as Element).setAttribute(
            'data-asset-id',
            `${text}_${computedColor}`
          );
        }
        retriesUsed.current += 1;
      }, retriesUsed.current ** 2 * 500);
    }
  };

  return (
    <styled.Wrapper
      className={className}
      size={size}
      shape={shape}
      opacity={opacity}
      data-asset-id={assetId(picture) || `${text}_${computedColor}`}
    >
      {(!picture || picture === '') && !noInitials ? (
        <styled.Initials
          shape={shape}
          src={initialsSrc}
          onError={handleAvatarError}
          opacity={opacity}
        />
      ) : (
        <styled.Avatar
          noInitials={noInitials}
          shape={shape}
          src={resizedImageUrl(picture, imgSize)}
          onError={handleAvatarError}
          opacity={opacity}
        />
      )}
      {showIndicator && (
        <styled.ActivityIndicator
          size={size}
          shape={activityShape}
          active={activity === true || timeUnitsAgo(activity, 'minutes') < 15}
        />
      )}
      {showMarker && <styled.Marker {...marker} size="xsmall" />}
    </styled.Wrapper>
  );
};

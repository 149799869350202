import { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import momentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { isEmpty } from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';

import { isValid } from 'components/utils/form-utils';
import { isValidAsDate } from 'components/utils/date';
import { Icon } from 'components/elements/icon';
import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';
import theme from 'themes';

import useDateFormat from 'hooks/useDateFormat';

import * as styled from './styles/calendar';

const InputCalendarComponent = (props) => {
  const picker = createRef();
  const dateFormat = useDateFormat();
  const [isFocused, setIsFocused] = useState(false);

  const {
    inputType,
    required,
    shouldValidate,
    customValidation,
    disabled,
    yearDropdown,
    timeSelect,
    date,
    intl,
    onDateChange,
    onFocus,
    ...otherProps
  } = props;

  const onFocusHandler = (event) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };
  const onBlurHandler = (event) => {
    const inputValue = event.target.value;
    setIsFocused(false);

    if (isEmpty(inputValue)) {
      onDateChange(null);
      return;
    }

    if (isValidAsDate(inputValue, dateFormat, true)) {
      onDateChange(moment(inputValue, dateFormat));
      return;
    }

    picker.current.handleSelect(moment(), event);
  };

  const placeholder = otherProps.placeholder?.id
    ? intl.formatMessage(otherProps.placeholder)
    : dateFormat;

  const yearDropdownProps = yearDropdown
    ? {
        showYearDropdown: true,
        dateFormatCalendar: 'MMMM',
      }
    : {};

  const isInputValid = isValid({
    inputValue: date,
    shouldValidate,
    inputType,
    required,
    customValidation,
  });
  const timeSelectProps = timeSelect
    ? {
        showTimeSelect: true,
        dateFormat: 'L HH:mm',
        timeFormat: 'HH:mm',
        timeIntervals: 10,
      }
    : {};

  return (
    <styled.DatePicker
      focus={isFocused}
      isValid={isInputValid}
      timeSelect={timeSelect}
    >
      {shouldValidate && !isInputValid && <ValidationErrorIndicator />}
      <DatePicker
        dateFormat={dateFormat}
        {...otherProps}
        {...yearDropdownProps}
        {...timeSelectProps}
        id={otherProps.id}
        selected={date}
        placeholderText={placeholder}
        disabled={disabled}
        onChange={onDateChange}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        dropdownMode="select"
        ref={picker}
      />
      <styled.Indicator
        onClick={() => {
          if (!disabled) {
            picker.current.setOpen(true);
          }
        }}
      >
        <Icon icon="CalendarBlank" size={2.5} color={theme.colors.primary} />
      </styled.Indicator>
    </styled.DatePicker>
  );
};

InputCalendarComponent.propTypes = {
  intl: PropTypes.object,
  date: momentPropTypes.momentObj,
  minDate: momentPropTypes.momentObj,
  maxDate: momentPropTypes.momentObj,
  placeholder: PropTypes.object,
  onDateChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  inputType: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  customValidation: PropTypes.func,
  yearDropdown: PropTypes.bool,
  timeSelect: PropTypes.bool,
  id: PropTypes.string,
  disabledKeyboardNavigation: PropTypes.bool,
  scrollableYearDropdown: PropTypes.bool,
  popperPlacement: PropTypes.string,
  popperModifiers: PropTypes.object,
};

InputCalendarComponent.defaultProps = {
  id: 'date',
  disabled: false,
  required: false,
  disabledKeyboardNavigation: true,
  yearDropdown: true,
  scrollableYearDropdown: true,
  minDate: undefined,
  maxDate: undefined,
  timeSelect: false,
};

export const InputCalendar = injectIntl(InputCalendarComponent);

// App skeleton
import { App } from 'containers/App';

// Admin routes
import { AdminContainer } from 'containers/Admin';

import { IntegrationsContainer } from 'containers/Integrations';

// Shared private routes
import { DashboardContainer } from 'containers/Dashboard';
import { SettingsContainer } from 'containers/Settings';
import { YourAccountContainer } from 'containers/YourAccount';
import { CompanyProfileContainer } from 'containers/CompanyProfile';
import { UserProfileContainer } from 'containers/UserProfile';
import { HotlistContainer } from 'containers/Hotlist';
import { SubscriptionPlanContainer } from 'containers/SubscriptionPlan';
import { SharedListContainer } from 'containers/SharedList';
import { ClientsContainer } from 'containers/Clients';
import { UserMessengerContainer } from 'containers/UserMessenger';
import { CandidatesContainer } from 'containers/Candidates';

// Shared public routes
import { LoginContainer } from 'containers/Login';
import { LogoutContainer } from 'containers/Logout';
import { SetPasswordContainer } from 'containers/SetPassword';
import { ForgotPasswordContainer } from 'containers/ForgotPassword';
import { ConfirmEmailContainer } from 'containers/ConfirmEmail';
import { SignUpContainer } from 'containers/SignUp';
import { SupplierHubContainer } from 'containers/SupplierHub';
import { CompanyProfilePublicContainer } from 'containers/CompanyProfile/Public';
import { NotFoundContainer } from 'containers/NotFound';
import { PublicSharedListContainer } from 'containers/PublicSharedList';
import { PublicCompanyPortalContainer } from 'containers/PublicCompanyPortal';
import { OnboardingContainer } from 'containers/Onboarding';
import { isCustomDomainPage } from 'containers/App/utils/helpers';
import { ShortUUIDConverterContainer } from 'containers/ShortUUIDConverter';
import { VizzlyReportingContainer } from 'containers/VizzlyReporting';

const dashboardRoutes = [
  {
    path: '/',
    component: DashboardContainer,
    exact: true,
  },
];

const clientsRoutes = [
  {
    path: '/clients',
    component: ClientsContainer,
  },
];

const vizzlyRoutes = [
  {
    path: '/reporting',
    component: VizzlyReportingContainer,
  },
];

const testVizzlyRoutes = [
  {
    path: '/test-reporting',
    component: VizzlyReportingContainer,
  },
];

const messengerRoutes = [
  {
    path: '/messages',
    component: UserMessengerContainer,
  },
];

const candidatesRoutes = [
  {
    path: '/candidates',
    component: CandidatesContainer,
  },
];

const supplierHubRoutes = [
  {
    path: '/public/vendor-hub',
    component: SupplierHubContainer,
  },
  {
    path: '/public/company/profile/:id',
    component: CompanyProfilePublicContainer,
  },
];

const shortUUIDConverterRoutes = [
  {
    path: '/public/uuid-converter',
    component: ShortUUIDConverterContainer,
  },
];

const settingsRoutes = [
  {
    path: '/settings',
    component: SettingsContainer,
  },
];

const yourAccountRoutes = [
  {
    path: '/account',
    component: YourAccountContainer,
  },
];

const companyRoutes = [
  {
    path: '/company/profile/:tab(about|users)',
    component: CompanyProfileContainer,
  },
  {
    path: '/company/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/company/profile',
    component: CompanyProfileContainer,
  },
];

const userRoutes = [
  {
    path: '/user/profile/:id?',
    component: UserProfileContainer,
  },
];

const accountRoutes = [
  {
    path: '/login/:token?',
    component: LoginContainer,
  },
  {
    path: '/itserve',
    component: LoginContainer,
  },
  {
    path: '/connect_account/bullhorn',
    component: LoginContainer,
  },
  {
    path: '/logout',
    component: LogoutContainer,
  },
  {
    path: '/signup',
    component: SignUpContainer,
  },
  {
    path: '/forgotpassword',
    component: ForgotPasswordContainer,
  },
  {
    path: '/setpassword',
    component: SetPasswordContainer,
  },
  {
    path: '/resetpassword',
    component: SetPasswordContainer,
  },
  {
    path: '/onboarding/:step?',
    component: OnboardingContainer,
  },
];

const adminRoutes = [
  {
    path: '/admin',
    component: AdminContainer,
  },
];

const integrationsRoutes = [
  {
    path: '/integrations',
    component: IntegrationsContainer,
  },
];

const hotlistRoutes = [
  {
    path: '/hotlist',
    component: HotlistContainer,
  },
];

const subscriptionPlanRoutes = [
  {
    path: '/subscription-plan',
    component: SubscriptionPlanContainer,
  },
];

const confirmEmailRoutes = [
  {
    path: '/confirm-email',
    component: ConfirmEmailContainer,
  },
];

const sharedListRoutes = [
  {
    path: '/:type(shared|submission|marketing)-lists/:id?',
    component: SharedListContainer,
  },
];

const publicSharedListRoutes = [
  {
    path: '/sl/:id',
    component: PublicSharedListContainer,
  },
  {
    path: '/cp/:id',
    component: PublicCompanyPortalContainer,
  },
];

const publicCompanyPortalRoutes = [
  {
    path: '/',
    component: PublicCompanyPortalContainer,
  },
];

const rootRoutes = () => {
  if (isCustomDomainPage()) {
    return publicCompanyPortalRoutes;
  }

  return dashboardRoutes;
};

const notFoundRoute = [
  {
    component: NotFoundContainer,
  },
];

export const Routes = [
  {
    component: App,
    routes: [
      ...adminRoutes,
      ...accountRoutes,
      ...confirmEmailRoutes,
      ...settingsRoutes,
      ...yourAccountRoutes,
      ...companyRoutes,
      ...userRoutes,
      ...clientsRoutes,
      ...vizzlyRoutes,
      ...testVizzlyRoutes,
      ...messengerRoutes,
      ...candidatesRoutes,
      ...integrationsRoutes,
      ...supplierHubRoutes,
      ...hotlistRoutes,
      ...subscriptionPlanRoutes,
      ...sharedListRoutes,
      ...publicSharedListRoutes,
      ...shortUUIDConverterRoutes,
      ...rootRoutes(),
      ...notFoundRoute,
      // NotFound must be very last route
      // which should only render, if there is no other match
    ],
  },
];

import { useIntl } from 'react-intl';

import { DynamicWidthInput } from 'components/form/dynamicWidthInput';

import * as styled from './styles/inputs';

type InputWithSuffixProps = {
  className?: string;
  placeholder?: I18nMessage;
  onChange?: (value: string) => void;
  value?: string;
  isValid?: boolean;
  readOnlySuffix: string;
};

export const InputWithSuffix = ({
  className,
  placeholder,
  onChange,
  value,
  isValid,
  readOnlySuffix,
}: InputWithSuffixProps) => {
  const intl = useIntl();

  return (
    <styled.InputWithSuffixWrapper className={className}>
      <DynamicWidthInput
        value={value}
        placeholder={placeholder ? intl.formatMessage(placeholder) : ''}
        onChange={(event) => onChange?.(event.target.value)}
        isValid={isValid}
        size="large"
      />
      <styled.Suffix>{readOnlySuffix}</styled.Suffix>
    </styled.InputWithSuffixWrapper>
  );
};

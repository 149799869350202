export const PORTAL_CREATE_REQUEST =
  'gustav/subscription/portal/CREATE_REQUEST';
export const PORTAL_CREATE_SUCCESS =
  'gustav/subscription/portal/CREATE_SUCCESS';
export const PORTAL_CREATE_ERROR = 'gustav/subscription/portal/CREATE_ERROR';

export const SUBSCRIPTION_CHANGE_REQUEST = 'gustav/subscription/CHANGE_REQUEST';
export const SUBSCRIPTION_CHANGE_SUCCESS = 'gustav/subscription/CHANGE_SUCCESS';
export const SUBSCRIPTION_CHANGE_ERROR = 'gustav/subscription/CHANGE_ERROR';

export const SUBSCRIPTION_PERIODS_FETCH_REQUEST =
  'gustav/subscription/periods/FETCH_REQUEST';
export const SUBSCRIPTION_PERIODS_FETCH_SUCCESS =
  'gustav/subscription/periods/FETCH_SUCCESS';
export const SUBSCRIPTION_PERIODS_FETCH_ERROR =
  'gustav/subscription/periods/FETCH_ERROR';

export const SUBSCRIPTION_CREDITS_FETCH_REQUEST =
  'gustav/subscription/credits/FETCH_REQUEST';
export const SUBSCRIPTION_CREDITS_FETCH_SUCCESS =
  'gustav/subscription/credits/FETCH_SUCCESS';
export const SUBSCRIPTION_CREDITS_FETCH_ERROR =
  'gustav/subscription/credits/FETCH_ERROR';

export const SUBSCRIPTION_ADD_SEATS_REQUEST =
  'gustav/subscription/seats/ADD_REQUEST';
export const SUBSCRIPTION_ADD_SEATS_SUCCESS =
  'gustav/subscription/seats/ADD_SUCCESS';
export const SUBSCRIPTION_ADD_SEATS_ERROR =
  'gustav/subscription/seats/ADD_ERROR';

import moment from 'moment';

import { RichTextEditor } from 'components/form/rte';
import { InputText } from '../../input/text';
import { InputAutocomplete } from '../../input/autocomplete';
import { InputMoney } from '../../money';
import { Select as InputOption } from '../../select/select';
import { Textarea } from '../../textarea/textarea';
import { Checkbox } from '../../checkbox/checkbox';
import { CheckboxList } from '../../checkboxList';
import { InputCalendar } from '../../calendar/calendar';
import { Toggle } from '../../toggle/toggle';
import { Tags } from '../../tags';
import { TagsAutocomplete } from '../../tagsAutocomplete';
import { TagsDropdown } from '../../tagsDropdown';
import { Upload } from '../../upload';
import { UploadWithBullhorn } from '../../uploadWithBullhorn';
import { IconPicker } from '../../IconPicker';
import { MonthYear } from '../../monthYear';
import { MonthYearRange } from '../../monthYear/range';
import { InputPhone } from '../../input/InputPhone';
import { InputWithSuffix } from '../../input/InputWithSuffix';
import { InputColor } from '../../color';

/**
 * @function inputFor
 * @param {object} inputObj
 * @param {UIComponent} CustomInputType
 * @return {UIComponent}
 *
 * @description based on the inputObj.inputType it will return an input type,
 *   which could be text, calendar, option, each ui-component has a default
 *   component with its styles (styled-component) however you can send
 *   CustomInputType if you need to send, for example, a custom Option
 */
export const inputFor = function inputFor(inputObj, CustomInputType) {
  const { shouldValidate, inputType } = inputObj;
  switch (inputType) {
    case 'option':
    case 'currency': {
      const FormFieldUI = CustomInputType || InputOption;
      return (
        <FormFieldUI
          inputType={inputType}
          disabled={inputObj.disabled}
          selected={inputObj.selected}
          selectionSource={inputObj.selectionSource}
          options={inputObj.options}
          optionsFn={inputObj.optionsFn}
          padding=".9rem 2rem .7rem 1rem"
          required={inputObj.required}
          shouldValidate={shouldValidate}
          onChange={(selected) => inputObj.fn(selected)}
          onFocus={inputObj.onFocus}
          selectTag="button"
          optionsHeight={inputObj.optionsHeight}
          optionsPosition={inputObj.optionsPosition}
          optionsTypeahead={inputObj.optionsTypeahead}
          size={inputObj.size}
          listWithoutPlaceholder={inputObj.listWithoutPlaceholder}
          placeholder={inputObj.placeholder}
          dropdownSize={inputObj.dropdownSize}
        />
      );
    }

    case 'textarea': {
      const FormFieldUI = CustomInputType || Textarea;
      return (
        <FormFieldUI
          id={inputObj.id}
          inputType={inputType}
          value={inputObj.inputValue}
          name={inputObj.name}
          placeholder={inputObj.placeholder}
          required={inputObj.required}
          maxlength={inputObj.maxlength}
          rows={inputObj.rows}
          showCharactersCounter={inputObj.showCharactersCounter}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
          maxHeight={inputObj.maxHeight}
        >
          {inputObj.inputValue}
        </FormFieldUI>
      );
    }

    case 'rte': {
      const FormFieldUI = CustomInputType || RichTextEditor;
      return (
        <FormFieldUI
          id={inputObj.id}
          name={inputObj.name}
          inputType={inputType}
          value={inputObj.inputValue}
          placeholder={inputObj.placeholder}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
          withFloatingTextTools={inputObj.withFloatingTextTools}
          promptSuggestions={inputObj.promptSuggestions}
          withButtonAskAI={inputObj.withButtonAskAI}
          withBarTextTools={inputObj.withBarTextTools}
          height={inputObj.height}
          minHeight={inputObj.minHeight}
          maxHeight={inputObj.maxHeight}
          getTextRef={inputObj.getTextRef}
          variablesList={inputObj.variablesList}
          variablesI18n={inputObj.variablesI18n}
          resizable={inputObj.resizable}
          dataManual={inputObj.dataManual}
          limit={inputObj.limit || inputObj.maxlength}
          showCharactersCounter={inputObj.showCharactersCounter}
          noNewLineOnEnter={inputObj.noNewLineOnEnter}
          editable={inputObj.editable}
          extraFloatingContentHeight={inputObj.extraFloatingContentHeight}
        />
      );
    }

    case 'checkbox':
      return (
        <Checkbox
          id={inputObj.id}
          inputType={inputType}
          checked={inputObj.checked}
          disabled={inputObj.disabled}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          value={inputObj.inputValue}
          name={inputObj.name}
          onChange={(value) => inputObj.fn(value)}
        />
      );

    case 'checkboxList':
      return (
        <CheckboxList
          checkboxOnLeft={inputObj.checkboxOnLeft}
          fullWidth={inputObj.fullWidth}
          items={inputObj.options}
          checked={inputObj.selected}
          idSelector={inputObj.idSelector}
          nameSelector={inputObj.nameSelector}
          iconContextSelector={inputObj.iconContextSelector}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
        />
      );

    case 'calendar': {
      const date = inputObj.date || inputObj.inputValue;
      return (
        <InputCalendar
          id={inputObj.id}
          inputType={inputType}
          placeholder={inputObj.placeholder}
          date={moment.isMoment(date) ? date : null}
          disabled={inputObj.disabled}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          onDateChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
          minDate={inputObj.minDate}
          maxDate={inputObj.maxDate}
          yearDropdown={inputObj.yearDropdown}
          customValidation={inputObj.customValidation}
        />
      );
    }

    case 'icon-picker': {
      return (
        <IconPicker
          id={inputObj.id}
          placeholder={inputObj.placeholder}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          value={inputObj.inputValue}
          onChange={(value) => inputObj.fn(value)}
        />
      );
    }

    case 'calendar_datetime': {
      const date = inputObj.date || inputObj.inputValue;
      return (
        <InputCalendar
          id={inputObj.id}
          inputType={inputType}
          placeholder={inputObj.placeholder}
          date={moment.isMoment(date) ? date : null}
          disabled={inputObj.disabled}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          onDateChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
          minDate={inputObj.minDate}
          maxDate={inputObj.maxDate}
          yearDropdown={inputObj.yearDropdown}
          timeSelect
        />
      );
    }

    case 'month_year': {
      return (
        <MonthYear
          id={inputObj.id}
          inputType={inputType}
          value={inputObj.inputValue}
          required={inputObj.required}
          placeholder={inputObj.placeholder}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          minDate={inputObj.minDate}
          maxDate={inputObj.maxDate}
          presentSelect={inputObj.presentSelect}
        />
      );
    }

    case 'month_year_range': {
      return (
        <MonthYearRange
          id={inputObj.id}
          inputType={inputType}
          value={inputObj.inputValue}
          required={inputObj.required}
          label={inputObj.label}
          placeholder={inputObj.placeholder}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          minDate={inputObj.minDate}
          maxDate={inputObj.maxDate}
          presentSelect={inputObj.presentSelect}
        />
      );
    }

    case 'toggle':
      return (
        <Toggle
          id={inputObj.id}
          inputType={inputType}
          disabled={inputObj.disabled}
          required={inputObj.required}
          shouldValidate={shouldValidate}
          options={inputObj.options}
          checked={inputObj.checked}
          name={inputObj.name}
          color={inputObj.color}
          icon={inputObj.icon}
          disabledIcon={inputObj.disabledIcon}
          size={inputObj.size}
          onChange={(value) => inputObj.fn(value)}
        />
      );

    case 'autocomplete':
      return (
        <InputAutocomplete
          id={inputObj.id}
          inputType={inputType}
          name={inputObj.name}
          value={inputObj.inputValue}
          required={inputObj.required}
          disabled={inputObj.disabled}
          minlength={inputObj.minlength}
          maxlength={inputObj.maxlength}
          placeholder={inputObj.placeholder}
          freetext={inputObj.freetext}
          clearOnChange={inputObj.clearOnChange}
          dropdownFooter={inputObj.dropdownFooter}
          dropdownFooterHeight={inputObj.dropdownFooterHeight}
          isValid={inputObj.isValid}
          shouldValidate={shouldValidate}
          inputIcon={inputObj.inputIconIndicator}
          onChange={(value) => inputObj.fn(value)}
          onAutocomplete={inputObj.fnAutocomplete}
          onFocus={inputObj.onFocus}
        >
          {inputObj.children}
        </InputAutocomplete>
      );

    case 'tags':
      return (
        <Tags
          id={inputObj.id}
          name={inputObj.name}
          required={inputObj.required}
          min={inputObj.min}
          max={inputObj.max}
          value={inputObj.inputValue}
          placeholder={inputObj.placeholder}
          isValid={inputObj.isValid}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
        />
      );

    case 'tags_autocomplete':
      return (
        <TagsAutocomplete
          id={inputObj.id}
          inputType={inputType}
          name={inputObj.name}
          value={inputObj.inputValue}
          quick={inputObj.quick}
          required={inputObj.required}
          min={inputObj.min}
          max={inputObj.max}
          minlength={inputObj.minlength}
          maxlength={inputObj.maxlength}
          placeholder={inputObj.placeholder}
          disabled={inputObj.disabled}
          freetext={inputObj.freetext}
          dropdownFooter={inputObj.dropdownFooter}
          dropdownFooterHeight={inputObj.dropdownFooterHeight}
          isValid={inputObj.isValid}
          shouldValidate={shouldValidate}
          inputIcon={inputObj.inputIconIndicator}
          onChange={(value) => inputObj.fn(value)}
          onAutocomplete={inputObj.fnAutocomplete}
          onFormat={inputObj.fnFormat}
          onFocus={inputObj.onFocus}
          quickListSimple={inputObj.quickListSimple}
          customDescription={inputObj.customDescription}
          tagsEmptyState={inputObj.tagsEmptyState}
          fnFormatQuickTag={inputObj.fnFormatQuickTag}
        >
          {inputObj.children}
        </TagsAutocomplete>
      );

    case 'tags_dropdown':
      return (
        <TagsDropdown
          id={inputObj.id}
          onChange={inputObj.fn}
          onFocus={inputObj.onFocus}
          options={inputObj.options}
          bullhornOptions={inputObj.bullhornOptions}
          selected={inputObj.selected}
          optionsHeight={inputObj.optionsHeight}
          dropdownSize={inputObj.dropdownSize}
          optionsPosition={inputObj.optionsPosition}
          disabledOptions={inputObj.disabledOptions}
          placeholder={inputObj.placeholder}
        />
      );

    case 'upload':
      return (
        <Upload
          id={inputObj.id}
          inputType={inputType}
          name={inputObj.name}
          files={inputObj.inputValue}
          required={inputObj.required}
          disabled={inputObj.disabled}
          loading={inputObj.loading}
          clearable={inputObj.clearable}
          shouldValidate={shouldValidate}
          detail={inputObj.placeholder}
          buttonDetail={inputObj.button}
          acceptedFormats={inputObj.acceptedFormats}
          acceptedDimensions={inputObj.acceptedDimensions}
          maxFileSize={inputObj.maxFileSize}
          onChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
        />
      );

    case 'upload_with_bullhorn':
      return (
        <UploadWithBullhorn
          id={inputObj.id}
          inputType={inputType}
          name={inputObj.name}
          files={inputObj.inputValue}
          required={inputObj.required}
          disabled={inputObj.disabled}
          loading={inputObj.loading}
          clearable={inputObj.clearable}
          shouldValidate={shouldValidate}
          detail={inputObj.placeholder}
          buttonDetail={inputObj.button}
          acceptedFormats={inputObj.acceptedFormats}
          acceptedDimensions={inputObj.acceptedDimensions}
          maxFileSize={inputObj.maxFileSize}
          onChange={(value) => inputObj.fn(value)}
          bullhornFilesProps={inputObj.bullhornFilesProps}
          onFocus={inputObj.onFocus}
        />
      );

    case 'money':
    case 'money_range':
    case 'money_decimal': {
      const FormFieldUI = CustomInputType || InputMoney;
      return (
        <FormFieldUI
          inputType={inputType}
          id={inputObj.id}
          name={inputObj.name}
          disabled={inputObj.disabled}
          readonly={inputObj.readonly}
          required={inputObj.required}
          min={inputObj.min}
          max={inputObj.max}
          value={inputObj.inputValue}
          placeholder={inputObj.placeholder}
          minPlaceholder={inputObj.minPlaceholder}
          maxPlaceholder={inputObj.maxPlaceholder}
          size={inputObj.size}
          isValid={inputObj.isValid}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          onKeyUp={inputObj.onKeyUp}
          onFocus={inputObj.onFocus}
          onBlur={inputObj.onBlur}
          onKeyPress={inputObj.onKeyPress}
          validationErrorIndicator={inputObj.validationErrorIndicator}
          onInnerRef={inputObj.onInnerRef}
          currencySelector={inputObj.withSelector}
        />
      );
    }

    case 'phone_number':
      return (
        <InputPhone
          value={inputObj.inputValue}
          onChange={(value) => inputObj.fn(value)}
          onFocus={inputObj.onFocus}
          placeholder={inputObj.placeholder}
          disabled={inputObj.disabled}
        />
      );

    case 'color':
      return (
        <InputColor
          value={inputObj.inputValue}
          disabled={inputObj.disabled}
          tooltip={inputObj.tooltip}
          onChange={(value) => inputObj.fn(value)}
        />
      );

    case 'text_with_suffix':
      return (
        <InputWithSuffix
          value={inputObj.inputValue}
          disabled={inputObj.disabled}
          tooltip={inputObj.tooltip}
          onChange={(value) => inputObj.fn(value)}
          readOnlySuffix={inputObj.readOnlySuffix}
        />
      );

    case 'text':
    case 'number':
    case 'password':
    default: {
      const FormFieldUI = CustomInputType || InputText;
      return (
        <FormFieldUI
          key={inputObj.key}
          id={inputObj.id}
          name={inputObj.name}
          disabled={inputObj.disabled}
          readonly={inputObj.readonly}
          required={inputObj.required}
          min={inputObj.min}
          max={inputObj.max}
          maxlength={inputObj.maxlength}
          value={inputObj.inputValue}
          placeholder={inputObj.placeholder}
          indicator={inputObj.inputIndicator}
          iconIndicator={inputObj.inputIconIndicator}
          inputType={inputType || 'text'}
          size={inputObj.size}
          isValid={inputObj.isValid}
          shouldValidate={shouldValidate}
          onChange={(value) => inputObj.fn(value)}
          onKeyUp={inputObj.onKeyUp}
          onFocus={inputObj.onFocus}
          onBlur={inputObj.onBlur}
          onKeyPress={inputObj.onKeyPress}
          autoFocus={inputObj.autoFocus}
          validationErrorIndicator={inputObj.validationErrorIndicator}
          clearable={inputObj.clearable}
          onClear={inputObj.onClear}
          showCharactersCounter={inputObj.showCharactersCounter}
          charactersCounterIntl={inputObj.charactersCounterIntl}
          showRightSideLabel={inputObj.showRightSideLabel}
          rightLabelText={inputObj.rightLabelText}
          onInnerRef={inputObj.onInnerRef}
        />
      );
    }
  }
};

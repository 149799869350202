import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isArray, isEmpty } from 'lodash';
import { ReactPageClick } from 'react-page-click';
import { FloatingMenu, posToDOMRect } from '@tiptap/react';
import { UNICODE } from 'components/utils/unicode';

import { getFixedDropdownOptionsSettings } from 'components/utils/dropdown';
import { TagEl } from 'components/navigation/menu/link';

import * as styled from '../styles';

const gapBetweenEditorWrapper = 45;

export function FloatingVariables({
  editor,
  parentElementRef,
  variablesList,
  dataManual,
  extraContentHeight,
}) {
  const [showList, setShowList] = useState(false);
  const listWrapper = useRef();

  if (!editor || !variablesList || isEmpty(variablesList)) return false;

  const baseDataManual = `${dataManual}.tokenSelector`;
  return (
    <FloatingMenu
      className="tooltip-shadow"
      editor={editor}
      element={parentElementRef}
      shouldShow={({ view, state }) => view.hasFocus() && state.selection.empty}
      tippyOptions={{
        onClickOutside: (instance) => {
          instance.hide();
        },
        getReferenceClientRect: () => {
          const parentElement = parentElementRef.current;

          if (!parentElement) {
            return {
              top: 0,
              left: 0,
              height: 0,
              width: 0,
            };
          }
          const {
            right: rightFromWrapper,
          } = parentElement.getBoundingClientRect();
          const { from, to } = editor.state.selection;
          const positionFromSelection = posToDOMRect(editor.view, from, to);

          const { top, height } = positionFromSelection;

          return {
            height,
            left: rightFromWrapper + gapBetweenEditorWrapper,
            top,
          };
        },
      }}
    >
      <ReactPageClick notify={() => setShowList(false)}>
        <styled.VariablesButton
          ref={listWrapper}
          onClick={() => {
            setShowList(!showList);
          }}
          data-manual={`${baseDataManual}.button`}
        >
          <styled.Icon icon="BracketsDots" color="textLight" />
          {listWrapper.current && (
            <VariablesList
              insertContent={editor.commands.insertContent}
              show={showList}
              parentElement={listWrapper.current}
              variablesList={variablesList}
              dataManual={baseDataManual}
              extraContentHeight={extraContentHeight}
            />
          )}
        </styled.VariablesButton>
      </ReactPageClick>
    </FloatingMenu>
  );
}

FloatingVariables.propTypes = {
  editor: PropTypes.object,
  parentElementRef: PropTypes.object,
  variablesList: PropTypes.array,
  dataManual: PropTypes.string,
  extraContentHeight: PropTypes.number,
};

export function VariablesList({
  show,
  parentElement,
  insertContent,
  variablesList,
  dataManual,
  extraContentHeight,
}) {
  if (!show) return false;

  const props = getFixedDropdownOptionsSettings({
    optionsPosition: 'top',
    element: parentElement,
    options: Array(12),
    optionsHeight: styled.VariablesListItemHeight,
    extraContentHeight,
  });

  return (
    <styled.VariablesList {...props} data-manual={`${dataManual}.tokenList`}>
      {variablesList.map(({ id, label, options }, index) => (
        <Fragment key={`${id}-fragment`}>
          <styled.VariablesListTitle
            key={id}
            divider={!!index}
            color="textLighter"
          >
            <FormattedMessage {...label} />
          </styled.VariablesListTitle>
          {options.map(({ linkName, slug, needFallback = '', tags }) => (
            <styled.VariablesListItem
              key={slug}
              role="button"
              onClick={() => {
                insertContent(
                  `${UNICODE.WORD_JOINER}<variable type="${slug}" needFallback="${needFallback}" autofocus="true"></variable>`
                );
              }}
            >
              <FormattedMessage {...linkName} />
              {isArray(tags) && tags.map((tag) => TagEl(tag, false))}
            </styled.VariablesListItem>
          ))}
        </Fragment>
      ))}
    </styled.VariablesList>
  );
}

VariablesList.propTypes = {
  show: PropTypes.bool,
  parentElement: PropTypes.object,
  insertContent: PropTypes.func,
  variablesList: PropTypes.array,
  dataManual: PropTypes.string,
  extraContentHeight: PropTypes.number,
};

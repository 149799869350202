import qs from 'qs';
import { get, some } from 'lodash';

import { PUBLIC_PATHS } from 'utils/authorization/authConstants';
import { getMetaContent } from 'components/utils/html';
import { isAlternativePortalDomain } from 'utils/checkEnvironment';

export const isPublicPath = ({ pathname }) => pathname.startsWith('/public');

export const isSharedListPath = ({ pathname }) => pathname.startsWith('/sl/');

export const isCompanyPortalPath = ({ pathname }) =>
  pathname.startsWith('/cp/');

export const isLoginOrSignupPath = ({ pathname }) =>
  pathname.startsWith('/login') || pathname.startsWith('/signup');

export const isLoginPath = ({ pathname }) => pathname.startsWith('/login');

export const isLogoutPath = ({ pathname }) => pathname.startsWith('/logout');

export const isPublicPortalPath = (location) => {
  const { search } = location;
  const redirect = get(
    qs.parse(search, { ignoreQueryPrefix: true }),
    'redirect',
    ''
  );
  const isSimpleLoginPath =
    isLoggingInToken(location) &&
    (isCustomDomainPage() ||
      isAlternativePortalDomain() ||
      isSharedListPath({ pathname: redirect }) ||
      isCompanyPortalPath({ pathname: redirect }));

  return (
    isSharedListPath(location) ||
    isCompanyPortalPath(location) ||
    isCustomDomainPath(location) ||
    isLoggingOut(location) ||
    isSimpleLoginPath
  );
};

export const isCustomDomainPath = ({ pathname }) =>
  (pathname === '/' ||
    pathname.startsWith('/requests') ||
    pathname.match(/^\/[a-zA-Z0-9]{22}/)) &&
  isCustomDomainPage();

export const isUnauthorizedPath = ({ pathname }) =>
  some(
    PUBLIC_PATHS,
    (path) => pathname === path || pathname.match(new RegExp(path))
  );

export const isSettingPassword = (location) => {
  const { pathname, query: { redirect } = {} } = location;
  return (
    pathname.startsWith('/setpassword') ||
    pathname.startsWith('/resetpassword') ||
    pathname.startsWith('/forgotpassword') ||
    (redirect && redirect.startsWith('/setpassword'))
  );
};

export const isOnboarding = ({ pathname }) =>
  pathname.startsWith('/onboarding');

export const isConfirmEmail = ({ pathname }) =>
  pathname.startsWith('/confirm-email');

export const isLoggingOut = ({ pathname }) => pathname.startsWith('/logout');

export const isLoggingInToken = ({ pathname, search }) =>
  pathname === '/login' &&
  search.match(/(magic_link_token|login_token|email_confirm_token)=/);

export const isSharedListCandidateModalPath = ({ pathname }) => {
  const pathToMatch = new RegExp(
    '^/(shared|submission|marketing)-lists/\\w*/(candidates|messages)/\\w*(/(request|share)/\\w*$|$)'
  );

  return pathname.match(pathToMatch) !== null;
};

export const isPublicProfileCandidatePath = ({ location }) => {
  const portalPathWithoutCompany = new RegExp('/\\w+/candidate/\\w+$');

  return (
    isPublicPortalPath(location) &&
    portalPathWithoutCompany.test(location.pathname)
  );
};

export const isWithSidebarPath = (location) =>
  !isSettingPassword(location) &&
  !isConfirmEmail(location) &&
  !isPublicPath(location) &&
  !isPublicPortalPath(location) &&
  !isLoggingOut(location) &&
  !isOnboarding(location);

export const isWithSubMenuPath = ({ pathname }) =>
  !pathname.startsWith('/shared-lists');

export const isSharedListsAllowedPath = (location, excludedPaths = []) => {
  const { pathname } = location;

  return (
    !excludedPaths.some((excludedPath) => pathname.startsWith(excludedPath)) &&
    (pathname.startsWith('/shared-lists') ||
      pathname.startsWith('/marketing-lists') ||
      pathname.startsWith('/submission-lists') ||
      pathname.startsWith('/clients') ||
      pathname.startsWith('/reporting') ||
      pathname.startsWith('/test-reporting') ||
      pathname.startsWith('/messages') ||
      pathname.startsWith('/candidates') ||
      pathname.startsWith('/settings') ||
      pathname.startsWith('/user') ||
      pathname.startsWith('/account') ||
      pathname.startsWith('/subscription-plan') ||
      pathname.startsWith('/integrations/bullhorn/shared-lists') ||
      pathname.startsWith('/integrations/bullhorn/clients') ||
      pathname.startsWith('/integrations/bullhorn/contacts') ||
      pathname.startsWith('/integrations/bullhorn/candidates') ||
      isLoggingOut(location) ||
      isSettingPassword(location) ||
      isConfirmEmail(location) ||
      isPublicPath(location) ||
      isPublicPortalPath(location))
  );
};

export const isCustomDomainPage = () =>
  !!getMetaContent("name='gustav:custom-domain-company-id'");
